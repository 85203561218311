import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'
import { Fade } from "react-reveal"

import Slider from "./Slider"
import Container from "../container"
import './styles.scss';
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
    query {
        allContentfulTestimonial {
            edges {
                node {
                    id
                    author {
                        name
                        title                        
                    }
                    content {
                        content
                    }
                }
            }
        }
    }
`

const Testimonials = () => {

  const { allContentfulTestimonial: { edges: testimonials } } = useStaticQuery(query);

  return (
    <div className="relative overflow-hidden">
      <Container noPadding>
        <ScrollableAnchor id="testimonials">
          <div />
        </ScrollableAnchor>

        <div className="px-6 md:px-0">
          <Fade left duration={1000}>
            <h3 className="text-secondary-magenta font-niramit text-sm md:text-lg font-bold uppercase mb-6 md:mb-12">Testimonials</h3>
          </Fade>

          <Fade left duration={1000} delay={400}>
            <h2 className="font-niramit text-3xl md:text-5xl text-primary-magenta">What Our  Clients Say?</h2>
          </Fade>
        </div>

        <div className="w-full xl:w-11/12 xl:ml-auto mt-20">
          <Slider testimonials={testimonials} />
        </div>
      </Container>
    </div>
  )
}

export default Testimonials;
