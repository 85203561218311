import React from "react";
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import laptop from '../../../images/features/rocket/main-laptop.svg';
import man from '../../../images/features/rocket/man.svg';
import humanLaptop from '../../../images/features/rocket/laptop.svg';
import woman from '../../../images/features/rocket/woman.svg';
import gear from '../../../images/features/rocket/gear.svg';
import smoke from '../../../images/features/rocket/smoke.svg';
import rocket from '../../../images/features/rocket/rocket.svg';
import dartsBG from '../../../images/features/rocket/darts-bg.svg';
import gearBG from '../../../images/features/rocket/gear-bg.svg';

import './styles.scss';
import { Bounce, Fade } from "react-reveal"

const FeatureRocket = () => {
  const query = graphql`
      query {
          file(relativePath: { eq: "ellipse-feature-left.png" }) {
              childImageSharp {
                  fluid(maxHeight: 708, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `;

  const { file: { childImageSharp: { fluid } } } = useStaticQuery(query);

  return (
    <div className="feature-image w-full md:h-auto relative">
      <Image fluid={fluid} />

      <div className="split-image feature-rocket-box absolute top-0">

        <Fade bottom>
          <img src={laptop} alt="laptop" className="laptop" />
        </Fade>

        <Fade left delay={500}>
          <img src={man} alt="man" className="man" />
        </Fade>

        <Bounce top delay={800}>
          <img src={humanLaptop} alt="laptop" className="humanLaptop" />
        </Bounce>

        <Fade right delay={500}>
          <img src={woman} alt="woman" className="woman" />
        </Fade>

        <Bounce top delay={800}>
          <img src={gear} alt="gear" className="gear" />
        </Bounce>

        <Fade delay={1200}>
          <img src={smoke} alt="smoke" className="smoke" />
        </Fade>

        <Bounce bottom delay={1400}>
          <img src={rocket} alt="rocket" className="rocket" />
        </Bounce>

        <Bounce top delay={1800}>
          <img src={dartsBG} alt="darts" className="darts-bg lg"/>
        </Bounce>

        <Bounce top delay={1900}>
          <img src={dartsBG} alt="darts" className="darts-bg sm"/>
        </Bounce>

        <Bounce top delay={1800}>
          <img src={gearBG} alt="gear" className="gear-bg lg"/>
        </Bounce>

        <Bounce top delay={1900}>
          <img src={gearBG} alt="gear" className="gear-bg sm"/>
        </Bounce>
      </div>
    </div>
  )
}

export default FeatureRocket;
