import React from 'react';
import Fade from 'react-reveal/Fade';

import './styles.scss';
import { faLayerGroup, faUsers } from "@fortawesome/free-solid-svg-icons"
import { faClone } from "@fortawesome/free-regular-svg-icons";
import ScrollableAnchor from "react-scrollable-anchor"
import { graphql, useStaticQuery } from "gatsby"
import FeatureCard from "./FeatureCard"

const icons = [faUsers, faLayerGroup, faClone]

const query = graphql`
    query {
        allContentfulBenefit(sort: {order: ASC, fields: createdAt}) {
            edges {
                node {
                    id
                    title
                    skills {
                        skills
                    }
                    content {
                        content
                    }
                    createdAt
                }
            }
        }
    }
`

const ExclusiveFeatures = () => {

  const { allContentfulBenefit: { edges: benefits } } = useStaticQuery(query);

  return (
    <div className="pt-12 xl:pt-24 w-full px-6 xl:px-0 xl:w-11/12 xxl:w-10/12 mx-auto">

      <ScrollableAnchor id='what-we-do'>
        <div />
      </ScrollableAnchor>

      <div className="-mx-4 flex flex-col md:flex-row flex-wrap lg:flex-row">
        <Fade left duration={500}>
          <div className="w-full xl:w-1/4 px-4 flex md:items-center justify-center lg:items-start">
            <div className="px-4 pt-12 xl:pl-12 xl:pt-24 pb-16">
              <div>
                <h2 className="font-niramit text-5xl leading-tight flex flex-col mb-12">
                  <span className="block">Services</span>
                  <span className="block pl-12">we offer</span>
                </h2>
              </div>

               {/* <a href="#" className="btn btn-bordered inline-block leading btn-primary uppercase">Learn more</a> */}
            </div>
          </div>
        </Fade>

        {benefits.map(({ node }, index) => (
          <Fade key={node.id} bottom duration={1000} delay={index * 200}>
            <div className="w-full md:w-full lg:w-1/3 xl:w-1/4 py-4 xl:py-0 px-4 flex">
              <FeatureCard
                isOdd={Math.abs(index % 2 === 1)}
                title={node.title}
                info={node.content.content}
                skills={node.skills}
                icon={icons[index > icons.length - 1 ? 1 : index]}
              />
            </div>
          </Fade>
        ))}
      </div>
    </div>
  )
};

export default ExclusiveFeatures;
