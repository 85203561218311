import React, { useRef } from "react"
import Fade from 'react-reveal/Fade';
import Typewriter from 'typewriter-effect';
import manSVG from '../../images/hero/man.svg';
import './styles.scss';
import { graphql, useStaticQuery } from "gatsby";
import { NAVIGATION } from "../../consts"

const query = graphql`
    query {
        allContentfulHero(limit: 1) {
            edges {
                node {
                    id
                    title
                    action
                    description {
                        description
                    }
                }
            }
        }
    }
`;

const Hero = () => {
  const parallaxRef = useRef();

  const { allContentfulHero: { edges } } = useStaticQuery(query);

  const hero = edges[0] && edges[0].node || {
    description: {}
  };

  return (
    <div className="bg-hero overflow-hidden pt-32 flex items-end">
      <div className="w-full lg:w-10/12 xl:w-10/12 xxl:w-8/12 flex mx-auto justify-between relative items-end">
        <div className="md:pb-6 lg:pb-6 xl:pb-16 xxl:pb-24 px-4 md:w-6/12 lg:w-6/12 xl:w-5/12 xxl:w-5/12 flex">
          <div className="w-full">
            <h1 className="font-niramit text-3xl xl:text-5xl xxl:text-6xl text-white leading-tight mb-8 xxl:mb-12">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.changeDelay(55).typeString(hero.title).start();
                }}
              />
            </h1>

            <Fade bottom delay={2000}>
              <p className="font-work text-alpha-white text-lg xl:text-xl xxl:text-2xl leading-loose opacity-0">
                {hero.description.description}
              </p>
            </Fade>

            <Fade bottom delay={2300}>
              <a href={NAVIGATION.WHAT_WE_DO.URL} className="btn btn-info btn-shadow btn-lg leading uppercase mt-12 mb-6 lg:mb-12 inline-block opacity-0 text-center">{hero.action}</a>
            </Fade>
          </div>
        </div>

        <div className="px-4 items-end md:w-6/12 lg:w-6/12 xl:w-6/12 xxl:w-6/12 relative hidden md:flex" ref={parallaxRef} >
          <Fade right>
            <div className="w-full hero-box flex items-end opacity-0 px-10">
              <img className="man" src={manSVG} alt="Inizio Inc." />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Hero;
