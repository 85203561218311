import React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from 'gatsby-image';
import ScrollableAnchor from 'react-scrollable-anchor';

import "./styles.scss";
import Fade from "react-reveal/Fade";


export const query = graphql`
    query {
        file(relativePath: { eq: "dots.png" }) {
            childImageSharp {
                fixed(width: 356, height: 356) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`

const ContactUs = () => {
  const { file: { childImageSharp: { fixed: dots } } } = useStaticQuery(query);

  return (
    <div className="w-full lg:w-10/12 xl:w-10/12 xxl:w-8/12 px-6 xl:px-0 mx-auto mt-16 xl:mt-48">
      <ScrollableAnchor id="contact">
          <div />
      </ScrollableAnchor>
      <Fade bottom>
        <div className="contact-card flex flex-col md:flex-row justify-between items-center px-6 xl:px-20 py-8 xl:py-24 relative overflow-hidden xl:overflow-visible">

          <div className="w-full text-center md:text-left">
            <h2 className="w-full md:w-8/12 font-niramit font-bold text-3xl text-primary-magenta capitalize mb-8">Want to launch your big idea with Inizio crew?</h2>

            <p className="w-full md:w-10/12 font-niramit text-alpha-purple">We would love to hear about your idea and possibly be your partner.</p>
          </div>

          {/* <Link to="/contact" className="btn btn-primary btn-xl rounded-lg uppercase mt-8 md:mt-0">Get in touch</Link> */}
          <div id="ic-cform"></div>
          <div className="contact-dots">
            <Image alt="dots" fixed={dots} />
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default ContactUs;
