import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import featureLaptop from "../../../images/features/laptop.svg"
import paper from "../../../images/features/paper.svg"
import human from "../../../images/features/human.svg"
import pie from "../../../images/features/pie-shape.svg"
import pinkPyramid from "../../../images/features/pink-pyramid.svg"
import bluePyramid from "../../../images/features/blue-pyramid.svg"
import borderCircle from "../../../images/features/blue-border-circle.svg"
import blueDotsShape from "../../../images/features/blue-dots-shape.svg"
import pinkDotsShape from "../../../images/features/pink-dots-shape.svg"
import pinkSquare from "../../../images/features/pink-square.svg"
import zigShape from "../../../images/features/zig-shape.svg"

import "./styles.scss";
import { Bounce } from "react-reveal"

const FeatureLaptop = () => {
  const query = graphql`
      query {
          file(relativePath: { eq: "ellipse-feature-right.png" }) {
              childImageSharp {
                  fluid(maxHeight: 708, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `;

  const { file: { childImageSharp: { fluid } } } = useStaticQuery(query);
  return (
    <div className="feature-image w-full md:h-auto relative">
      <Image fluid={fluid} />

      <div className="split-image feature-laptop-box absolute top-0">
        <Bounce top>
          <img src={featureLaptop} alt="laptop" className="laptop" />
        </Bounce>

        <Bounce top delay={300}>
          <img src={paper} alt="paper" className="paper" />
        </Bounce>

        <Bounce top delay={500}>
          <img src={human} alt="human" className="human" />
        </Bounce>

        <Bounce top delay={700}>
          <img src={pie} alt="pie" className="pie" />
        </Bounce>

        <Bounce top delay={800}>
          <img src={pinkPyramid} alt="pink pyramid" className="pinkPyramid" />
        </Bounce>

        <Bounce top delay={900}>
          <img src={bluePyramid} alt="blue pyramid" className="bluePyramid" />
        </Bounce>

        <Bounce top delay={1600}>
          <img src={borderCircle} alt="blue circle" className="borderCircle" />
        </Bounce>

        <Bounce top delay={1100}>
          <img src={blueDotsShape} alt="blue shape" className="blueDotsShape" />
        </Bounce>

        <Bounce top delay={1300}>
          <img src={pinkDotsShape} alt="pink shape" className="pinkDotsShape" />
        </Bounce>

        <Bounce top delay={1600}>
          <img src={pinkSquare} alt="pink square" className="pinkSquare" />
        </Bounce>

        <Bounce top delay={1800}>
          <img src={zigShape} alt="zig zag" className="zigShape" />
        </Bounce>
      </div>
    </div>
  )
}

export default FeatureLaptop;
