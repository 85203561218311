import React, { useEffect } from "react"
import { goToAnchor } from 'react-scrollable-anchor'
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Portfolio from "../components/Portfolio"
import Testimonials from "../components/Testimonials"
import ExclusiveFeatures from "../components/ExclusiveFeatures"
import ContactUs from "../components/ContactUs"
import Hero from "../components/Hero"
import Features from "../components/Features"
import Numbers from "../components/Numbers"
import Fade from 'react-reveal/Fade';

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;


const IndexPage = ({ data, location: { state }}) => {
  const {site: {siteMetadata}}  = data;

  useEffect(() => {
    if (state && state.anchor) {
      setTimeout(() => {
        goToAnchor(state.anchor, true)
      }, 100)
    }
  }, []);

  return (
    <Layout isHeaderPresent={false}>
      <SEO title={siteMetadata.title} />
            
      <Hero />
      <ExclusiveFeatures />

      <Features />

      <Portfolio />

      <Testimonials />

      <Numbers />

      <ContactUs />
    </Layout>
  )
}



export default IndexPage
