import React, { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

const FeatureCard = ({ isOdd, icon, title, info, skills }) => (
  <div className={`feature-card font-niramit px-6 xl:pl-16 xl:pr-8 pt-8 pb-16 w-full ${isOdd && 'odd'}`}>
    <div>
      <div className="relative px-6 xl:px-0">
        <div className="mb-12 relative">
          {isOdd ? (
            <div className="icon-block materia flex items-center justify-center">
              <FontAwesomeIcon color="#fff" size="2x" icon={icon} />
            </div>
          ) : (
            <Fragment>
              <div className="icon-block darkly flex items-center justify-center">
                <FontAwesomeIcon color="#fff" size="2x" icon={icon} />
              </div>
              <div className="ellipse"/>
            </Fragment>
          )}
        </div>
      </div>

      <div className="flex flex-col justify-between">
        <div className="mb-6">
          <h3 className="text-2xl font-bold">{title}</h3>

          <p className="text-lg mt-6 opacity-75">{info}</p>
        </div>

        {skills && (
          <ul className="text-lg opacity-75" dangerouslySetInnerHTML={{ __html: skills.skills }} />
        )}
      </div>
    </div>
  </div>
);

export default FeatureCard;
