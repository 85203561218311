import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from "gatsby"
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import './styles.scss';
import Container from "../container"

export const query = graphql`
    query {
        allContentfulHistoryLabel {
            edges {
                node {
                    id
                    title
                    count
                }
            }
        }
        
        allContentfulHistoryBubble(sort: {fields: order}) {
            edges {
                node {
                    id
                    title
                    suffix
                    count
                }
            }
        }
        
        file(relativePath: { eq: "numbers-bg.png" }) {
            childImageSharp {
                fixed(width: 521, height: 478) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`

const Circle = ({ title, label, style }) => (
  <div className="number-circle flex items-center flex-col justify-center lg:p-4 text-center lg:text-left" style={style}>
    <span className="font-niramit font-bold text-xl text-primary-magenta">{title}</span>

    <div className="flex items-end">{label}</div>
  </div>
);

const Label = ({ prefix = null, suffix, content }) => (
  <div className="font-niramit text-primary-blue font-bold text-2xl uppercase">
    {prefix}
    <span className="text-2xl lg:text-5xl">{content}</span>
    {suffix}
  </div>
)

const Numbers = () => {

  const { allContentfulHistoryLabel: { edges: labels }, allContentfulHistoryBubble: { edges: bubbles }, file: { childImageSharp: { fixed: bg } } } = useStaticQuery(query);

  const props = [
    {
      style: null,
      rest: {
        left: true,
      }
    },
    {
      style: {
        marginTop: -140
      },
      rest: {
        top: true,
      }
    },
    {
      style: {
        marginTop: 80
      },
      rest: {
        bottom: true,
      }
    },
    {
      style: {
        marginTop: -80
      },
      rest: {
        right: true,
      }
    }
  ]

  return (
    <div className="relative overflow-hidden md:overflow-visible">
      <div className="numbers-bg">
        <Image fixed={bg} alt="numbers" />
      </div>

      <Container className="pt-24 pb-24">

        <Fade left>
          <div>
            <h3 className="text-secondary-magenta font-niramit text-sm md:text-lg font-bold uppercase mb-6 md:mb-12">Numbers</h3>
            <h2 className="font-niramit text-3xl md:text-5xl text-primary-magenta capitalize">Numbers Always Tell the truth</h2>
          </div>
        </Fade>

      </Container>

      <div className="container px-6 mx-auto pt-24">
        <div className="flex flex-wrap flex-row justify-between">

          {bubbles.map(({ node }, index) => (
            <Zoom key={node.id} duration={1000} delay={index * 300} {...props[index].rest}>
              <div className="w-1/2 md:w-1/4 flex justify-center">
                <Circle
                  style={{
                    ...props[index].style,
                    animationDelay: `${index * 1500}ms`
                  }}
                  title={node.title}
                  label={<Label content={node.count} prefix={node.prefix} suffix={node.suffix} />}
                />
              </div>
            </Zoom>
          ))}
        </div>

        {/*<div className="w-full lg:w-8/12 mx-auto pt-16 lg:pt-32">
          <ul className="flex flex-col md:flex-row items-center justify-between">
            {labels.map(({ node }, index) => {
              const animations = [
                {
                  left: true
                },
                {
                  bottom: true
                },
                {
                  right: true
                }
              ];

              return (
                <li key={node.id} className="font-niramit font-bold text-lg text-gray-900 uppercase flex items-center mb-4 lg:mb-0">
                  <Zoom cascade {...animations[index]}>
                    <span className="text-primary-blue">{node.count}</span>
                    <span className="ml-2">{node.title}</span>
                  </Zoom>
                </li>
              )
            })}
          </ul>
          </div> */}
      </div>
    </div>
  )
}

export default Numbers;
