import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import './styles.scss';

const SliderItem = ({ delay, testimonial }) => (
  <div className="px-6 md:px-8 xl:px-24 mt-6 mb-16">
    <div className="testimonial testimonial-card px-6 xl:px-10 pb-20 pt-16 flex items-start flex-col lg:flex-row">

      <div className="testimonial-quotes mx-auto lg:mr-6" />

      <div className="mt-4 w-full">
        <p className="font-niramit text-xl text-primary-magenta opacity-75">
          {testimonial.content && testimonial.content.content}
        </p>

        <div className="flex flex-col lg:flex-row items-center mt-10">  
          <div className="default flex items-center justify-center">
            <FontAwesomeIcon size="2x" icon={faUserCircle} className="testimonial-avatar lg mb-8 lg:mb-0 lg:mr-8" alt={testimonial.author.name} />
          </div>
          <div className="text-center md:text-left">
            <h4 className="font-niramit font-bold text-secondary-magenta text-xl">
              {testimonial.author && testimonial.author.name}
            </h4>
            <p className="font-niramit text-primary-blue">
              {testimonial.author && testimonial.author.title}
            </p>
          </div>

        </div>
      </div>

    </div>
  </div>
)

SliderItem.propTypes = {
  delay: PropTypes.number
}

SliderItem.defaultProps = {
  delay: 100
}

export default SliderItem;
