import React, { useRef } from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-slick";


import './styles.scss';

import { Fade, Zoom } from "react-reveal"
import SliderItem from "./SliderItem";

import arrowLeft from '../../../images/arrow-left.png';
import arrowRight from '../../../images/arrow-right.png';

const data = [
  {
    avatarUrl: 'http://auraqatar.com/projects/Anakalabel/media//vesbrand/designer2.jpg',
    clientName: 'V Joe',
    testimonial: 'Inizio has a great pool of experts who built and deployed our apps in a few weeks. They have unique skills to overcome diverse project challenges and adhering to milestone deliveries.'
  },
  {
    avatarUrl: 'http://auraqatar.com/projects/Anakalabel/media//vesbrand/designer2.jpg',
    clientName: 'J Kuang',
    testimonial: 'Working with Inizio team since Feb 2018 building complex cross-platform mobile apps. They work both independently and collaboratively, and deliver solid apps on tight schedule.'
  },
  {
    avatarUrl: 'http://auraqatar.com/projects/Anakalabel/media//vesbrand/designer2.jpg',
    clientName: 'M Sona',
    testimonial: 'Inizio has not only very talented engineers, but are very dedicated and sincere about their work. They did a fantastic job with my project. The engineers were always willing to go above and beyond of what was asked.'
  },
]
const settings = {
  arrows: true,
  infinite: true,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Slider = ({ testimonials }) => {

  const slider = useRef(null);

  return (
    <div className="mb-6 xl:mb-12 xl:-mx-24">
      <Zoom top delay={200}>
        <Carousel ref={slider} {...settings}>
          {testimonials.map(({ node }, index) => <SliderItem key={node.id} delay={index * 150} testimonial={node} />)}
        </Carousel>
      </Zoom>

      <Fade right duration={1000}>
        <div className="rotating-system">
          <div className="orbit depth-1" />
          <div className="orbit depth-2" />
          <div className="orbit depth-3" />
          <div className="orbit depth-4" />
        </div>
      </Fade>

      <Fade bottom duration={500} delay={1000}>
        <div className="flex items-center justify-center">
          <button className="slider-arrow" onClick={() => slider.current.slickPrev()}>
            <img src={arrowLeft} alt="prev"/>
          </button>
          <button className="slider-arrow slider-arrow-next" onClick={() => slider.current.slickNext()}>
            <img src={arrowRight} alt="next"/>
          </button>
        </div>
      </Fade>
    </div>
  )
}

export default Slider;
