import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGem } from "@fortawesome/free-regular-svg-icons";
import ScrollableAnchor from 'react-scrollable-anchor'

import "./styles.scss";
import { Fade, Flip, Zoom } from "react-reveal"
import { graphql, useStaticQuery } from "gatsby";
import FeatureLaptop from "./FeatureLaptop"
import FeatureRocket from "./FeatureRocket"

const Feature = ({ isReverse, image, data }) => (
  <div className={`${isReverse && "reverse"} relative mb-24`}>
    <ScrollableAnchor id='why-us'>
        <div />
      </ScrollableAnchor>
    <div className="hidden xl:block ellipse feature-ellipse-bg sm" />
    <div className="hidden xl:block ellipse feature-ellipse-bg lg" />
    <div className="hidden xl:block ellipse feature-ellipse-bg lg" />
    <div className="w-full xl:w-8/12 mx-auto px-6 xl:px-0">
      <div className={`relative feature flex justify-between flex-col-reverse md:flex-row ${isReverse ? 'md:flex-row-reverse text-right' : 'md:flex-row'}`}>

        <div className="w-full md:w-6/12 xl:w-5/12">
          <div className="relative mb-10 flex icon-container">
            <Flip bottom delay={200}>
              <div className="text-secondary-blue icon-block default flex items-center justify-center">
                <FontAwesomeIcon size="2x" icon={faGem} />
              </div>
            </Flip>

            <Zoom top delay={200}>
              <div className="ellipse" />
            </Zoom>
          </div>

          <Fade bottom>
            <h2 className="capitalize font-niramit font-bold text-4xl text-secondary-magenta mb-6 w-full md:w-8/12 feature-title">{data.title}</h2>
          </Fade>

          <Fade bottom delay={300}>
            <div className="font-niramit text-lg text-alpha-magenta leading-relaxed mb-16" dangerouslySetInnerHTML={{__html: data.content.content}} />
          </Fade>

          {/* <Zoom bottom delay={100}>
            <a href={data.url} className="btn btn-primary">Read More</a>
</Zoom> */}
        </div>

        <div className="w-full md:w-6/12 xl:w-6/12 flex items-center">

          {image}

        </div>
      </div>
    </div>
  </div>
);

const Features = () => {

  const query = graphql`
    query {
        allContentfulFeature(sort: {fields: createdAt, order: DESC}, limit: 2) {
            edges {
                node {
                    id
                    title
                    content {
                        content
                    }
                }
            }
        }
    }
  `;

  const { allContentfulFeature: { edges: features }} = useStaticQuery(query);


  const images = [ <FeatureRocket />, <FeatureLaptop/>];

  return (
    <div className="relative">
      <div className="mt-24 xl:mt-48 pb-12">

        {features.map(({ node }, index) => (
          <Feature
            key={node.id}
            data={node}
            image={images[index]}
            isReverse={index % 2 === 1}
          />
        ))}
      </div>
    </div>
  )
}

export default Features;
